.App {
    text-align: center;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.containermargins {
    display: flex;
    margin-top: 100px;
    margin-bottom: 50px;
    align-items: center;
    justify-content: center;
}

.titles {
    font-size: 42px;
    text-align: center;
    margin-bottom: 25px;
}

.projectTitle {
    font-family: JetBrains Mono;
    font-size: 28px;
    text-align: center;
}

.aboutmedesc {
    font-size: 22px;
    font-family: JetBrains Mono;
    text-align: center;
}

.desc {
    font-size: 22px;
    font-family: JetBrains Mono;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
}

.tweentitle {
    margin-bottom: 25px;
}

.NavRow {
    display: flex;
    flex-direction: row;
    align-content: space-around;
}

.NavIcons {
    width: 16;
}

.profile {
    width: 250px;
    height: auto;
    margin-right: 75px;
    margin-left: 75px;
}

.projecticon {
    width: 125px;
    height: auto;
    align-content: center;
    margin-bottom: 30px;
}

.projectrow {
    margin-bottom: 80px;
    margin-top: 300px;
}

.projectdesc {
    font-family: "JetBrains Mono";
    font-size: 20px;
    text-align: center;
    justify-content: center;
    align-items: center;
}
